import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { LanguageSelectBar } from "./footers/language-select-bar";
import goBlLogo from "assets/portal/images/goe_logo_white.svg";
import biBegaLogo from "assets/portal/images/bi_bega.png";
import ts2Logo from "assets/portal/images/bi_logo_game.png";
import krLogo from "assets/portal/images/small_karahan_logo.png";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const disablePathName = location.pathname?.split("/")[1];
  const [isConCode, setIsConCode] = useState("");

  useEffect(() => {
    const conCode: string = localStorage.getItem("country_code") || "";
    setIsConCode(conCode);
  }, []);

  return (
    <>
      {disablePathName !== "launcher" ? (
        disablePathName !== "sign-out" ? (
          <footer
            className="relative flex flex-col items-center justify-center w-full py-16 text-gray-200 font-opensans"
            style={{ backgroundColor: "#0a0d0f" }}
          >
            <div className="flex flex-col items-center justify-center mb-6 w-36">
              <LanguageSelectBar />
            </div>
            <ul className="flex flex-col items-center justify-center text-sm uppercase space-y-2 divide-coolGray-700 text-coolGray-400
            sm:flex-row sm:divide-x sm:space-y-0 sm:mb-6
            ">
              <li className="px-6 transition duration-300 ease-in-out hover:underline hover:text-rose-600">
                <NavLink to={"/policy/privacy"}>
                  {t("main_banners.privacy_policy")}
                </NavLink>
              </li>
              <li className="px-6 transition duration-300 ease-in-out hover:underline hover:text-rose-600">
                <NavLink to={"/policy/eula"}>{t("main_banners.eula")}</NavLink>
              </li>
              <li className="px-6 transition duration-300 ease-in-out hover:underline hover:text-rose-600">
                <NavLink to={"/policy/terms"}>
                  {t("main_banners.terms")}
                </NavLink>
              </li>
              <li className="px-6 transition duration-300 ease-in-out hover:underline hover:text-rose-600">
                <NavLink to={"/policy/ugc"}>UGC</NavLink>
              </li>
            </ul>

            <ul className="flex items-center justify-center mb-6 space-x-2 md:space-x-36 w-11/12 mb:w-full">
              <li className="">
                <Link to="/">
                  {isConCode === "TR" ? (
                    <img src={krLogo} alt="Karahan Logo" className="ml-2" />
                  ) : i18n.language === "tr" ? (
                    <img src={krLogo} alt="Karahan Logo" className="ml-2" />
                  ) : (
                    <img src={ts2Logo} alt="Twelve sky 2 Logo" />
                  )}
                </Link>
              </li>
              <li className="">
                <Link to="/">
                  <img src={goBlLogo} alt="Genius orc logo" className="h-20" />
                </Link>
              </li>
              <li className="">
                <a
                  href="http://12sky2.begagames.co.kr/main"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={biBegaLogo} alt="Bega logo" />
                </a>
              </li>
            </ul>
            <p className="mb-2 capitalize text-xs text-center md:text-md">
              © 2025 <span className="mr-1 font-bold">Genius Orc</span>
              Entertainment Inc. {t("footer.all_right_reserved")}
            </p>
            {isConCode === "KR" ? (
              <p
                className="my-2 text-sm text-center font-opensans"
                style={{ color: "#728587" }}
              >
                8, Pungdeokcheon-ro 139beon-gil, Suji-gu, Yongin-si,
                Gyeonggi-do, Republic of Korea 16837
                <br />
                Tel: +82 031-889-9781, Fax: +82 031-889-9782, Support email:
                <a
                  href="mailto:support@geniusorc.com"
                  className="ml-1 hover:underline hover:text-coolGray-400"
                >
                  support@geniusorc.com
                </a>
                <br />
                Company Registration: 545-86-02000, Online Business
                Registration: 2021-YONGINSUJI-1340
              </p>
            ) : null}
          </footer>
        ) : null
      ) : null}
    </>
  );
};
